export const Instagram = () => (
  <svg
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.495 11.9974C15.098 11.9974 12.3258 14.6948 12.3258 18C12.3258 21.3052 15.098 24.0026 18.495 24.0026C21.892 24.0026 24.6642 21.3052 24.6642 18C24.6642 14.6948 21.892 11.9974 18.495 11.9974ZM36.9979 18C36.9979 15.5143 37.0211 13.0512 36.8776 10.57C36.7341 7.68803 36.0584 5.1303 33.8925 3.02288C31.722 0.910954 29.0979 0.258013 26.1359 0.118418C23.5812 -0.0211759 21.0497 0.0013394 18.4997 0.0013394C15.945 0.0013394 13.4134 -0.0211759 10.8634 0.118418C7.90144 0.258013 5.27272 0.915457 3.10679 3.02288C0.936241 5.13481 0.265175 7.68803 0.121706 10.57C-0.0217637 13.0557 0.00137658 15.5188 0.00137658 18C0.00137658 20.4812 -0.0217637 22.9488 0.121706 25.43C0.265175 28.312 0.940869 30.8697 3.10679 32.9771C5.27734 35.089 7.90144 35.742 10.8634 35.8816C13.4181 36.0212 15.9496 35.9987 18.4997 35.9987C21.0543 35.9987 23.5859 36.0212 26.1359 35.8816C29.0979 35.742 31.7266 35.0845 33.8925 32.9771C36.0631 30.8652 36.7341 28.312 36.8776 25.43C37.0257 22.9488 36.9979 20.4857 36.9979 18ZM18.495 27.2357C13.2422 27.2357 9.00292 23.111 9.00292 18C9.00292 12.889 13.2422 8.76426 18.495 8.76426C23.7479 8.76426 27.9871 12.889 27.9871 18C27.9871 23.111 23.7479 27.2357 18.495 27.2357ZM28.3759 10.543C27.1495 10.543 26.1591 9.57931 26.1591 8.386C26.1591 7.1927 27.1495 6.22905 28.3759 6.22905C29.6023 6.22905 30.5927 7.1927 30.5927 8.386C30.5931 8.66936 30.536 8.95 30.4247 9.21186C30.3134 9.47371 30.1502 9.71163 29.9442 9.912C29.7383 10.1124 29.4938 10.2712 29.2247 10.3795C28.9555 10.4878 28.6671 10.5433 28.3759 10.543Z"
      fill="currentColor"
    />
  </svg>
)
