import {CrossIcon} from 'assets/icons/cross'
import {FC, useState} from 'react'
import {StepOne} from './StepOne'
import {StepTwoConsultation} from './StepTwoConsultation'
import {StepTwoTest} from './StepTwoTest'
import {StepThree} from './StepThree'
import {StepTwoConsultationAndTest} from './StepTwoConsultationAndTest'

type Props = {
  setConsultationModalMode: (
    consultationModalMode: 'consulting' | 'order_test' | ''
  ) => void
  consultationModalMode: 'consulting' | 'order_test' | ''
}

export type ConsultationTypeParams = {
  test: boolean
  consultation: boolean
}

export const ConsultationModal: FC<Props> = ({
  setConsultationModalMode,
  consultationModalMode,
}) => {
  const [currentStep, setCurrentStep] = useState(2)

  const closeModal = () => setConsultationModalMode('')

  return (
    <div className="fixed top-0 h-full w-full backdrop-blur bg-[#616161B2] z-10 flex justify-center items-center">
      <div className="flex flex-col bg-white rounded-xl overflow-hidden w-[1011px]">
        <div className="flex justify-end">
          <button
            onClick={closeModal}
            className="rounded-bl-xl bg-[#FCDAD3] flex justify-center items-center h-[60px] w-[103px] text-[#353535] hover:bg-red-default hover:text-white transition-all"
          >
            <CrossIcon />
          </button>
        </div>
        {/* {currentStep === 1 && (
          <StepOne
            setCurrentStep={setCurrentStep}
            setConsultationTypeParams={setConsultationTypeParams}
          />
        )} */}
        {currentStep === 2 && consultationModalMode === 'consulting' && (
          <StepTwoConsultation setCurrentStep={setCurrentStep} />
        )}
        {currentStep === 2 && consultationModalMode === 'order_test' && (
          <StepTwoTest setCurrentStep={setCurrentStep} />
        )}
        {/* {currentStep === 2 &&
          consultationTypeParams.consultation &&
          consultationTypeParams.test && (
            <StepTwoConsultationAndTest setCurrentStep={setCurrentStep} />
          )} */}
        {currentStep === 3 && <StepThree closeModal={closeModal} />}
      </div>
    </div>
  )
}
