import {Disclosure, Transition} from '@headlessui/react'
import {FC, useReducer, useState} from 'react'
import clsx from 'clsx'
import {LANG} from 'constants/constants'
import {useCategories} from 'hooks/useCategories'
import {useTranslation} from 'react-i18next'
import {Article, Articles, TranslatedText} from 'api/types'
import {getJson} from 'api/getJson'
import {TopicItem} from './TopicItem'

type Props = {
  className?: string
}

export const TopicsMobileDisclosure: FC<Props> = ({className}) => {
  const {i18n} = useTranslation()
  const {categories} = useCategories()
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const [categoryArticles, setCategoryArticles] = useState<Article[][]>([])

  const fetchArticles = async (fileName: string) => {
    const response = await getJson<Articles>(`/articles/${fileName}`)
    return response.articles
  }

  const onClickCategory = async (index: number) => {
    if (!categoryArticles[index]) {
      const articles = await fetchArticles(categories[index].fileName)
      setCategoryArticles(prev => {
        prev[index] = articles
        return prev
      })
      forceUpdate()
    }
  }

  const getText = (text: TranslatedText) =>
    text?.[i18n.language as LANG] || text?.[LANG.en] || ''

  return (
    <ul className={clsx('flex flex-col mt-10 text-center font-montserrat', className)}>
      {categories.map((section, i) => (
        <Disclosure
          key={section.fileName}
          className="border-white border-b-[5px] last:border-none"
          as="li"
        >
          <Disclosure.Button
            onClick={() => onClickCategory(i)}
            className="w-full py-[29px] transition-all text-18-semi text-white bg-red-default"
          >
            {getText(section.title)}
          </Disclosure.Button>
          <Transition
            enter="transition duration-200 ease-out"
            enterFrom="transform opacity-0"
            enterTo="transform opacity-100"
            leave="transition duration-175 ease-out"
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0"
          >
            <Disclosure.Panel as="ul" className="text-gray-500 flex flex-col">
              {categoryArticles[i]?.map(topic => (
                <Disclosure
                  key={topic.title[LANG.en]}
                  className="border-b-[5px] border-red-default last:border-none"
                  as="li"
                >
                  <Disclosure.Button className="w-full transition-all text-18-semi py-[29px] bg-white text-red-default">
                    {getText(topic.title)}
                  </Disclosure.Button>

                  <Transition
                    enter="transition duration-200 ease-out"
                    enterFrom="transform opacity-0"
                    enterTo="transform opacity-100"
                    leave="transition duration-175 ease-out"
                    leaveFrom="transform opacity-100"
                    leaveTo="transform opacity-0"
                  >
                    <Disclosure.Panel className="text-gray-500 bg-white py-2.5">
                      <TopicItem content={getText(topic.content)} />
                    </Disclosure.Panel>
                  </Transition>
                </Disclosure>
              ))}
            </Disclosure.Panel>
          </Transition>
        </Disclosure>
      ))}
    </ul>
  )
}
