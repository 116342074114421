import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import MSM_Logo from 'assets/images/MSM.png'
import {LanguageSelect} from './LanguageSelect'
import {Link} from 'react-router-dom'

type Props = {
  setConsultationModalMode: (
    setConsultationModalMode: 'consulting' | 'order_test'
  ) => void
}

export const DesktopHeader: FC<Props> = ({setConsultationModalMode}) => {
  const {t} = useTranslation()

  const openConsultationModal = () => setConsultationModalMode('consulting')
  const openOrderTestModal = () => setConsultationModalMode('order_test')

  return (
    <header className="768:block hidden absolute top-[30px] left-[70px]">
      <nav className="flex items-center">
        <Link to="/" className="hover:opacity-80 transition-all shrink-0">
          <img className="pr-[30px]" src={MSM_Logo} alt="MSM Logo" />
        </Link>
        <ul className="flex items-center gap-[30px] font-montserrat text-34-bold text flex-wrap">
          <li>
            <Link
              className="p-2.5 bg-white rounded-full text-red-default transition-all hover:opacity-80"
              to="/contacts"
            >
              {t('menu.contacts')}
            </Link>
          </li>
          <li>
            <button
              onClick={openOrderTestModal}
              className="p-2.5 bg-white rounded-full text-red-default transition-all hover:opacity-80"
            >
              {t('menu.order_test')}
            </button>
          </li>
          <li>
            <button
              onClick={openConsultationModal}
              className="p-2.5 bg-white rounded-full text-red-default transition-all hover:opacity-80"
            >
              {t('menu.consult')}
            </button>
          </li>

          <li>
            <LanguageSelect className="pr-4" />
          </li>
        </ul>
      </nav>
    </header>
  )
}
