import {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Listbox} from '@headlessui/react'
import {LANG_ARR, LANG} from 'constants/constants'

type Props = {
  className?: string
}

export const LanguageSelect: FC<Props> = ({
  className,
}) => {
  const { t, i18n } = useTranslation()
  const [currLanguage, setCurrLanguage] = useState(i18n.language)
  
  const changeLanguage = (lang: LANG) => {
    i18n.changeLanguage(lang);
    setCurrLanguage(lang);
  }

  return (
    <div className={className}>
      <Listbox value={currLanguage} onChange={changeLanguage}>
        <Listbox.Button className="p-2.5 bg-white rounded-full text-red-default transition-all hover:opacity-80">
          {t('lang_key')}
        </Listbox.Button>
        <Listbox.Options className="absolute mt-5 space-y-5">
          {LANG_ARR.map(language => {
            if (i18n.language === language) return null

            return (
              <Listbox.Option
                className="cursor-pointer p-2.5 bg-white rounded-full text-red-default transition-all hover:opacity-80"
                key={language}
                value={language}
              >
                {t('lang_key', { lng: language })}
              </Listbox.Option>
            )
          })}
        </Listbox.Options>
      </Listbox>
    </div>
  )
}
