import {FC} from 'react'
import {TopicTabs} from './components/TopicTabs'
import {TopicsWrapper} from './components/TopicsWrapper'
import clsx from 'clsx'
import { useCategories } from 'hooks/useCategories'

type Props = {
  className?: string
}

export const InfoSection: FC<Props> = ({className}) => {
  const { 
    categories,
    activeCategoryIndex, setActiveCategoryIndex,
    goToNextCategory,
    goToPrevCategory
  } = useCategories()

  return (
    <section className={clsx('w-full', className)}>
      <TopicTabs
        categories={categories}
        activeCategoryIndex={activeCategoryIndex}
        onChangeCategory={setActiveCategoryIndex}
      />
      <TopicsWrapper
        activeCategoryFileName={categories[activeCategoryIndex]?.fileName || ''}
        goToNextCategory={goToNextCategory}
        goToPrevCategory={goToPrevCategory}
      />
    </section>
  )
}
