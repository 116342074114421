import emailjs from '@emailjs/browser'

const SERVICE_ID = process.env.REACT_APP_EMAIL_JS_SERVICE_ID || 'wtf'
const CONSULTATION_ONLY_TEMPLATE_ID =
  process.env.REACT_APP_EMAIL_JS_CONSULTATION_ONLY_TEMPLATE_ID
const TEST_ONLY_TEMPLATE_ID = process.env.REACT_APP_EMAIL_JS_TEST_ONLY_TEMPLATE_ID
const PUBLIC_KEY = process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY

type ConsultationTypes = 'consultation_only' | 'test_only' | 'consultation_and_test'

const LETTER_TEMPLATES: Record<ConsultationTypes, string> = {
  consultation_only: CONSULTATION_ONLY_TEMPLATE_ID!,
  test_only: TEST_ONLY_TEMPLATE_ID!,
  consultation_and_test: CONSULTATION_ONLY_TEMPLATE_ID!,
} as const

export const sendEmail = async (
  values: Record<string, string>,
  type: ConsultationTypes
) => {
  const templateId = LETTER_TEMPLATES[type]

  const res = await emailjs.send(SERVICE_ID, templateId, values, PUBLIC_KEY)

  return res
}
