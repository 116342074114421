import '../index.scss'

export const EmptyArticle = () => {
  return (
    <article className="content">
      <p className="text-28-semi">
        This section of the website will be available very soon!
      </p>
    </article>
  )
}
