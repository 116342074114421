import {FC} from 'react'
import PhoneWithArrow from 'assets/images/phone_with_arrow.png'
import {useTranslation} from 'react-i18next'

type Props = {
  closeModal: () => void
}

export const StepThree: FC<Props> = ({closeModal}) => {
  const {t} = useTranslation()

  return (
    <div className="px-[84px] pb-[50px] flex flex-col items-center">
      <span className="text-green-default text-36-bold uppercase mb-[50px]">
        {t('consultation.we_have_received_your_request')}
      </span>
      <div className="flex space-x-6 items-center mb-[30px]">
        <img alt="phone with arrow" src={PhoneWithArrow} />
        <span className="text-18-normal text-left max-w-[186px]">
          {t('consultation.you_can_expect_to_hear_from_us_within_a_single_business_day')}
        </span>
      </div>
      <button
        onClick={closeModal}
        className="w-[310px] mx-auto flex justify-center items-center py-[17px] px-[25px] bg-green-default rounded-full transition-all text-white hover:opacity-80"
      >
        <span className="text-22-bold font-roboto">{t('consultation.ok')}</span>
      </button>
    </div>
  )
}
