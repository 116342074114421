import {FC, useEffect, useRef} from 'react'
import {CHECKPOINTS, Place, PlaceBlock} from 'components/Contacts'
import {wait} from 'utils/wait'
import clsx from 'clsx'

const MAP_ID = 'test-map'

type Props = {
  places: Place[]
  placesWrapperClassName?: string
  placeBlockClassName?: string
}

export const PlacesWithMap: FC<Props> = ({
  places,
  placesWrapperClassName,
  placeBlockClassName,
}) => {
  const mapRef = useRef<HTMLDivElement>(null)

  let map: google.maps.Map | undefined

  const initMap = async () => {
    let count = 0

    while (!window.google) {
      count++
      if (count > 8) {
        console.error('Google Maps API not loaded')
        return
      }
      await wait(500)
    }

    const firstCheckpointCoords = CHECKPOINTS[0].coordinates

    const {Map} = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary
    map = new Map(document.getElementById(MAP_ID) as HTMLElement, {
      center: firstCheckpointCoords,
      zoom: 17,
    })

    new google.maps.Marker({
      position: firstCheckpointCoords,
      map,
    })
  }

  const goTo = (coords: Place['coordinates']) => {
    map?.setCenter(coords)
    mapRef?.current?.scrollIntoView({behavior: 'smooth', block: 'end'})

    new google.maps.Marker({
      position: coords,
      map,
    })
  }

  useEffect(() => {
    initMap()
  }, [])

  return (
    <div className="bg-white">
      <div
        className={clsx(
          'grid 768:grid-rows-none 768:grid-cols-3 gap-10',
          placesWrapperClassName ?? '1024:py-[50px] py-5 max-w-[1200px]'
        )}
      >
        {places.map(places => (
          <PlaceBlock
            key={places.address}
            place={places}
            onClick={() => goTo(places.coordinates)}
            className={clsx(placeBlockClassName ?? 'text-left max-w-[320px]')}
          />
        ))}
      </div>
      <div className="h-[600px]" id={MAP_ID} ref={mapRef} />
    </div>
  )
}
