import { getCategoriesFromJson } from "api/getCategoriesFromJson";
import { Category } from "api/types";
import { useEffect, useState } from "react";

export const useCategories = () => {
  const [categories, setCategories] = useState<Category[]>([])
  const [activeCategoryIndex, setActiveCategoryIndex] = useState<number>(0)

  const getCategories = async () => {
    const response = await (await getCategoriesFromJson()).categories;
    setCategories(response)
  }

  const goToNextCategory = () => {
    if (activeCategoryIndex !== categories.length - 1) {
      setActiveCategoryIndex((i) => i + 1)
    }
  }

  const goToPrevCategory = () => {
    if (activeCategoryIndex !== 0) {
      setActiveCategoryIndex((i) => i - 1)
    }
  }

  useEffect(() => {
    getCategories()
  }, [])

  return {
    categories,
    activeCategoryIndex,
    setActiveCategoryIndex,
    goToNextCategory,
    goToPrevCategory
  }
}