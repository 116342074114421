import {FC, useEffect, useRef, useState} from 'react'
import {TopicsList} from './TopicsList'
import figures from 'assets/images/figures.png'
import {Arrow} from 'assets/icons/arrow'
import {EmptyArticle} from './Topics/EmptyArticle'
import {LANG} from 'constants/constants'
import {Article, Articles, CategoryButtons, TranslatedText} from 'api/types'
import {getJson} from 'api/getJson'
import {useTranslation} from 'react-i18next'
import {TopicItem} from './TopicItem'

type Props = {
  activeCategoryFileName: string
  goToNextCategory: () => void
  goToPrevCategory: () => void
}

export const TopicsWrapper: FC<Props> = ({
  activeCategoryFileName, goToNextCategory, goToPrevCategory
}) => {
  const topicWrapperRef = useRef<HTMLDivElement>(null)

  const { i18n } = useTranslation();
  const [articles, setArticles] = useState<Article[]>([])
  const [activeArticleI, setActiveArticleI] = useState<number>(0)
  const [categoryButtons, setCategoryButtons] = useState<CategoryButtons>()
  const [isChangedToPrevCategory, setIsChangedToPrevCategory] = useState<boolean>(false)

  const fetchArticles = async () => {
    const response = (await getJson<Articles>(`/articles/${activeCategoryFileName}`))
    setArticles(response.articles)
    setCategoryButtons(response.buttons)

    if (isChangedToPrevCategory) {
      setActiveArticleI(response.articles.length - 1)
      setIsChangedToPrevCategory(false)
    } else {
      setActiveArticleI(0)
    }
  }

  useEffect(() => {
    if (activeCategoryFileName) {
      fetchArticles()
    } else {
      setArticles([])
    }
  }, [activeCategoryFileName])

  const getText = (text?: TranslatedText) => text?.[i18n.language as LANG] || text?.[LANG.en] || ''

  const getArticlesTitles = (arr: Article[]) => arr.map(
    article => ({ title: article.title })
  )

  const getArticleContent = () => {
    const article: Article | undefined = articles[activeArticleI]
    return getText(article?.content)
  }

  const isEmptySection = () => {
    return !getArticleContent();
  }

  const getPrevArticleTitle = () => {
    const article: Article | undefined = articles[activeArticleI - 1]
    const title = getText(article?.title)

    if (title) {
      return title
    }

    return getText(categoryButtons?.prev)
  }

  const getNextArticleTitle = () => {
    const article: Article | undefined = articles[activeArticleI + 1]
    const title = getText(article?.title)

    if (title) {
      return title
    }

    return getText(categoryButtons?.next)
  }

  const goToNextArticle = () => {
    scrollArticleIntoView()
    if (activeArticleI === articles.length - 1) {
      goToNextCategory()
      return
    }
    setActiveArticleI((i) => i + 1)
  }

  const goToPrevArticle = () => {
    scrollArticleIntoView()
    if (activeArticleI === 0) {
      goToPrevCategory()
      setIsChangedToPrevCategory(true)
      return
    }
    setActiveArticleI((i) => i - 1)
  }

  const scrollArticleIntoView = () => {
    topicWrapperRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'})
  }

  return (
    <div className="flex min-h-[1300px]">
      <div className="flex flex-col basis-[297px] 1280:basis-[384px] shrink-0">
        <div className="h-[100px] bg-white flex items-center justify-center">
          <img src={figures} alt="Абстрактные фигуры" />
        </div>
        <div className="bg-red-default p-2.5 grow">
          <TopicsList
            articles={getArticlesTitles(articles)}
            activeArticleI={activeArticleI}
            onChangeArticle={setActiveArticleI}
          />
        </div>
      </div>
      <div className="pt-[92px] px-6 1280:pr-[172px] pb-[150px] 1280:pl-[115px]" ref={topicWrapperRef}>
        {isEmptySection() ? (
          <EmptyArticle />
        ) : (
          <TopicItem
            key={`${activeArticleI}${i18n.language}${activeCategoryFileName}`}
            content={getArticleContent()}
          />
        )}

        <div className="flex justify-between mt-[115px] space-x-2 text-14-semi 1280:text-18-semi font-montserrat">
          {getPrevArticleTitle() ? (
            <button
              className="flex items-center uppercase text-left max-w-[428px] py-2 pr-5 1280:py-[17px] 1280:px-[25px] bg-green-default rounded-full transition-all text-white hover:opacity-80 space-x-[5px]"
              onClick={() => goToPrevArticle()}
            >
              <Arrow className="shrink-0" direction="left" />
              <span>{getPrevArticleTitle()}</span>
            </button>
          ) : (
            <div />
          )}
          {getNextArticleTitle() ? (
            <button
              className="flex items-center uppercase text-left max-w-[428px] py-2 pl-5 1280:py-[17px] 1280:px-[25px] bg-green-default rounded-full transition-all text-white hover:opacity-80 space-x-[5px]"
              onClick={() => goToNextArticle()}
            >
              <span>{getNextArticleTitle()}</span>
              <Arrow className="shrink-0" direction="right" />
            </button>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  )
}
