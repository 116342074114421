import msm_bg_mobile from 'assets/images/msm_background_mobile.png'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'

export const StartSection: FC = () => {
  const {t} = useTranslation()

  return (
    <section className="bg-cover bg-no-repeat 768:pt-[273px] 768:pl-[70px] w-full 768:h-[100vh] 768:bg-msm-desktop bg-right font-montserrat mb-7 768:mb-[unset]">
      <img className="768:hidden" src={msm_bg_mobile} alt="" />
      <div className="768:text-white text-black-default hidden 1280:block text-center 768:text-left 768:max-w-[620px] px-7 768:px-[unset]">
        <h1 className="text-36-bold 768:text-60-bold mb-5 768:mb-12 bg-red-default90 rounded-3xl p-3">
          {t('start_section.msm')}
          <br />
          {t('start_section.checkpoint')}
        </h1>
        <p className="text-20-hard 768:text-32-normal bg-red-default90 rounded-3xl p-3">
          {t('start_section.description')}
        </p>
      </div>
    </section>
  )
}
