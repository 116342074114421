import {FC} from 'react'
import clsx from 'clsx'
import {LANG} from 'constants/constants'
import {Category} from 'api/types'
import { useTranslation } from 'react-i18next'

type Props = {
  categories: Category[]
  onChangeCategory: (i: number) => void
  activeCategoryIndex: number
}

export const TopicTabs: FC<Props> = ({
  categories,
  onChangeCategory,
  activeCategoryIndex
}) => {
  const { i18n } = useTranslation();

  const changeCategory = (index: number) => {
    onChangeCategory(index)
  }

  return (
    <ul className="flex border-t border-white">
      {categories.map((category, index) => {
        const isSelectedSection = activeCategoryIndex === index

        return (
          <li
            key={category.fileName}
            className={clsx(
              'flex h-[80px] 1280:h-[120px] justify-center last:border-none items-center basis-1/5 px-5 border-r border-white text-18-bold 1280:text-28-bold transition-all',
              isSelectedSection
                ? 'bg-white hover:opacity-[unset] text-red-default'
                : 'bg-red-default hover:opacity-80 text-white'
            )}
          >
            <button
              className="w-full h-full"
              onClick={() => changeCategory(index)}
            >
              {category.title[i18n.language as LANG]}
            </button>
          </li>
        )
      })}
    </ul>
  )
}
