import {FC} from 'react'
import clsx from 'clsx'
import parse, {DOMNode, Element} from 'html-react-parser'
import {PlacesWithMap} from './Topics/Testing/PlacesWithMap'
import {CHECKPOINTS, HOSPITALS} from 'components/Contacts'

type Props = {
  className?: string
  content: string
}

export const TopicItem: FC<Props> = ({className, content}) => {
  const replace = (domNode: DOMNode) => {
    if ((domNode as Element).attribs?.id) {
      switch ((domNode as Element).attribs.id) {
        case 'PlacesWithMapCheckpoints':
          return <PlacesWithMap places={CHECKPOINTS} />
        case 'PlacesWithMapHospitals':
          return <PlacesWithMap places={HOSPITALS} />
      }
    }
  }

  return (
    <article className={clsx('content', className)}>{parse(content, {replace})}</article>
  )
}
