export const Youtube = () => (
  <svg
    width="53"
    height="36"
    viewBox="0 0 53 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2 25.7143L34.9535 18L21.2 10.2857V25.7143ZM51.834 5.58C52.1785 6.78857 52.417 8.40857 52.576 10.4657C52.7615 12.5229 52.841 14.2971 52.841 15.84L53 18C53 23.6314 52.576 27.7714 51.834 30.42C51.1715 32.7343 49.6345 34.2257 47.2495 34.8686C46.004 35.2029 43.725 35.4343 40.227 35.5886C36.782 35.7686 33.6285 35.8457 30.7135 35.8457L26.5 36C15.3965 36 8.48 35.5886 5.7505 34.8686C3.3655 34.2257 1.8285 32.7343 1.166 30.42C0.8215 29.2114 0.583 27.5914 0.424 25.5343C0.2385 23.4771 0.159 21.7029 0.159 20.16L0 18C0 12.3686 0.424 8.22857 1.166 5.58C1.8285 3.26571 3.3655 1.77429 5.7505 1.13143C6.996 0.797143 9.275 0.565714 12.773 0.411428C16.218 0.231428 19.3715 0.154286 22.2865 0.154286L26.5 0C37.6035 0 44.52 0.411429 47.2495 1.13143C49.6345 1.77429 51.1715 3.26571 51.834 5.58Z"
      fill="currentColor"
    />
  </svg>
)
