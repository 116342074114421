import {Field, FormikErrors, FormikTouched, FormikValues} from 'formik'
import {FC} from 'react'
import clsx from 'clsx'

type MsmFieldProps = {
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  name: string
  placeholder?: string
  as?: string
  type?: string
  className?: string
}
export const MsmField: FC<MsmFieldProps> = ({
  errors,
  touched,
  name,
  placeholder,
  as,
  type,
  className,
}) => {
  return (
    <div
      className={clsx(
        'relative flex-col items-start flex space-y-2',
        className || 'w-full'
      )}
    >
      <Field
        className={clsx(
          'bg-[#F1F1F1] px-[50px] py-[30px] text-18-normal text-[#565656] w-full grow placeholder:text-[#7C7C7C] rounded-xl focus:outline-none',
          !errors[name] && touched[name] && 'border-green-default border',
          errors[name] && touched[name] && 'border-red-default border'
        )}
        id={name}
        as={as}
        name={name}
        placeholder={placeholder}
        type={type}
      />
      <span className="absolute -bottom-5 text-14-normal text-red-default">
        {/* @ts-ignore */}
        {touched[name] ? errors[name] : ''}
      </span>
    </div>
  )
}
