/**
 * Represents the type of values associated with the properties of an object.
 * @template T - The object type.
 */
export type ObjectKeysType<T> = T[keyof T]

export const LANGUAGES = {
  ENG: 'ENG',
  RUS: 'RUS',
  EST: 'EST',
} as const

export enum LANG {
  en = 'en',
  ru = 'ru',
  et = 'et',
}

export const LANG_ARR = [LANG.en, LANG.ru, LANG.et];

export type LanguagesValues = ObjectKeysType<typeof LANGUAGES>
