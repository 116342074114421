import './App.css'
import './index.css'
import {Route, Routes} from 'react-router-dom'
import {MsmMain} from './pages/msm/MsmMain'
import './index.css'
import {Contacts} from 'components/Contacts'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/">
          <Route index element={<MsmMain />} />
          <Route path="/contacts" element={<Contacts />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
