import {Form, FormikProvider, useFormik} from 'formik'
import {FC, useState} from 'react'
import * as Yup from 'yup'
import {MsmField} from './MsmField'
import {sendEmail} from 'utils/sendEmail'
import {useTranslation} from 'react-i18next'
import clsx from 'clsx'

type Props = {
  setCurrentStep: (step: number) => void
}

export const StepTwoTest: FC<Props> = ({setCurrentStep}) => {
  const {t} = useTranslation()

  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false)

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t('formik.too_short'))
      .max(50, t('formik.too_long'))
      .required(t('formik.required')),
    email: Yup.string().email(t('formik.invalid_email')).required(t('formik.required')),
    address: Yup.string()
      .min(2, t('formik.too_short'))
      .max(80, t('formik.too_long'))
      .required(t('formik.required')),
    zipcode: Yup.string()
      .min(2, t('formik.too_short'))
      .max(80, t('formik.too_long'))
      .required(t('formik.required')),
    city: Yup.string()
      .min(2, t('formik.too_short'))
      .max(80, t('formik.too_long'))
      .required(t('formik.required')),
    country: Yup.string()
      .min(2, t('formik.too_short'))
      .max(80, t('formik.too_long'))
      .required(t('formik.required')),
  })

  const sendTestEmail = (values: Record<string, string>) => {
    const testValues = {
      name: values.name,
      email: values.email,
      address: values.address,
      zipcode: values.zipcode,
      city: values.city,
      country: values.country,
    }

    setIsSendButtonDisabled(true)
    sendEmail(testValues, 'test_only')
      .then(() => setCurrentStep(3))
      .catch(err => {
        formik.setFieldError('address', String(err.text))
      })
      .finally(() => setIsSendButtonDisabled(false))
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      address: '',
      zipcode: '',
      city: '',
      country: '',
    },
    onSubmit: sendTestEmail,
    validationSchema: SignupSchema,
  })

  return (
    <div className="px-[84px] pb-[50px] flex flex-col items-start">
      <span className="text-green-default text-left text-36-bold uppercase mb-[30px]">
        {t('consultation.order_a_rapid_hiv_test')}
      </span>
      <FormikProvider value={formik}>
        <Form className="w-full">
          <div className="flex flex-col space-y-5 text-24-normal">
            <div className="flex justify-between space-x-5">
              <MsmField
                name="name"
                errors={formik.errors}
                touched={formik.touched}
                placeholder={t('consultation.your_name')}
              />
              <MsmField
                name="email"
                errors={formik.errors}
                touched={formik.touched}
                placeholder={t('consultation.your_email')}
                type="email"
              />
            </div>

            {/* <div className="relative">
              <Autocomplete
                className={clsx(
                  'bg-[#F1F1F1] px-[50px] py-[30px] text-18-normal text-[#565656] w-full grow placeholder:text-[#7C7C7C] rounded-xl focus:outline-none',
                  !formik.errors.address &&
                    formik.touched.address &&
                    'border-green-default border',
                  formik.errors.address &&
                    formik.touched.address &&
                    'border-red-default border'
                )}
                options={{
                  types: ['address'],
                }}
                onClick={() => formik.setFieldTouched('address')}
                onPlaceSelected={place =>
                  formik.setFieldValue('address', place.formatted_address)
                }
              />
            </div> */}

            <div className="flex justify-between space-x-5">
              <MsmField
                name="address"
                errors={formik.errors}
                touched={formik.touched}
                placeholder={t('consultation.address')}
              />
              <MsmField
                name="zipcode"
                errors={formik.errors}
                touched={formik.touched}
                placeholder={t('consultation.zipcode')}
              />
            </div>
            <div className="flex justify-between space-x-5">
              <MsmField
                name="city"
                errors={formik.errors}
                touched={formik.touched}
                placeholder={t('consultation.city')}
              />
              <MsmField
                name="country"
                errors={formik.errors}
                touched={formik.touched}
                placeholder={t('consultation.country')}
              />
            </div>
          </div>

          <p className="font-montserrat mt-5">
            {t('consultation.privacy_policy_notice')}{' '}
            <a
              className="text-green-default hover:opacity-80 underline"
              href={t('footer.privacy_policy_link')}
            >
              {t('consultation.privacy_policy_link_text')}
            </a>
          </p>

          <button
            disabled={isSendButtonDisabled}
            className={clsx(
              'mt-8 w-[310px] mx-auto flex justify-center items-center py-[17px] px-[25px] bg-green-default rounded-full transition-all text-white hover:opacity-80 space-x-[5px]',
              isSendButtonDisabled && 'opacity-80'
            )}
            type="submit"
          >
            <span className="text-22-bold font-roboto">
              {t('consultation.make_an_order')}
            </span>
          </button>
        </Form>
      </FormikProvider>
    </div>
  )
}
