import {FC} from 'react'

type Props = {
  direction: 'left' | 'right'
  className?: string
}

export const Arrow: FC<Props> = ({direction, className}) => {
  if (direction === 'left') {
    return (
      <svg
        className={className}
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M29 22.5H20V29.5L7 18.5L20 7.5V14.5H29V22.5Z" fill="white" />
      </svg>
    )
  } else {
    return (
      <svg
        className={className}
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 14.5H16V7.5L29 18.5L16 29.5V22.5H7L7 14.5Z" fill="white" />
      </svg>
    )
  }
}
