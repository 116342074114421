import {FC, useState} from 'react'
import clsx from 'clsx'
import {LANG} from 'constants/constants'
import {TranslatedText} from 'api/types'
import {useTranslation} from 'react-i18next'

type Props = {
  articles: { title: TranslatedText }[],
  activeArticleI: number,
  onChangeArticle: (i: number) => void,
}

export const TopicsList: FC<Props> = ({
  articles,
  activeArticleI,
  onChangeArticle
}) => {
  const { i18n } = useTranslation()

  const changeArticle = (i: number) => {
    onChangeArticle(i)
  }

  return (
    <ul className="text-center font-montserrat">
      {articles.map((topic, i) => {
        const isSelectedTopic = activeArticleI === i

        return (
          <li
            key={topic.title.en}
            className={clsx(
              'border-b-[5px] border-white transition-all text-16-semi 1280:text-18-semi',
              isSelectedTopic
                ? 'bg-white text-red-default hover:opacity-[unset]'
                : 'text-white bg-red-default hover:opacity-80'
            )}
          >
            <button
              className="w-full h-full py-5 uppercase"
              onClick={() => changeArticle(i)}
            >
              {topic.title[i18n.language as LANG] || topic.title[LANG.en]}
            </button>
          </li>
        )
      })}
    </ul>
  )
}
