export const Facebook = () => (
  <svg
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37 18.1102C37 8.10799 28.7177 0 18.5023 0C8.28234 0.00224972 0 8.10799 0 18.1125C0 27.1496 6.76553 34.6412 15.6074 36V23.3453H10.9136V18.1125H15.612V14.1192C15.612 9.58155 18.3751 7.07537 22.5995 7.07537C24.625 7.07537 26.7407 7.42857 26.7407 7.42857V11.883H24.4077C22.1117 11.883 21.3949 13.2801 21.3949 14.7132V18.1102H26.5234L25.7048 23.3431H21.3926V35.9977C30.2345 34.6389 37 27.1474 37 18.1102Z"
      fill="currentColor"
    />
  </svg>
)
