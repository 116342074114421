export const ArrowLeftIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.3333 18.3332H13L22.3333 8.99984L20 6.6665L6.66663 19.9998L20 33.3332L22.3333 30.9998L13 21.6665H33.3333V18.3332Z"
      fill="white"
    />
  </svg>
)
